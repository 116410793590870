import React, { useState } from "react";
import { Layout } from "../components";
import { Link } from "gatsby";
import {
  SvgWebsite,
  SvgShop,
  SvgBranding,
  SvgBrand,
} from "../components/brief";

const Brief = ({ location: { pathname } }) => {
  const [isShaking, shake] = useState(false);
  return (
    <Layout
      seo={{
        title: "Brief",
        href: pathname,
        lang: "pl",
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/brief/",
      }}
    >
      <section className="container-fluid brief_main_section">
        <h1>Witamy</h1>
        <p className="desc">
          Wypełnienie poniższego formularza pozwoli nam na przygotowanie oferty dopasowanej do Twoich potrzeb. Cały proces nie powinien zająć Ci więcej niż 20 minut.
        </p>
        <h4>Wybierz usługę, którą jesteś zainteresowany/a</h4>

        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <Link
              to="/brief-strona-internetowa/"
              className="brief_link brief_link__website"
            >
              <SvgWebsite />
              <p>Strona internetowa</p>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-6">
            <Link to="/brief-sklep/" className="brief_link brief_link__shop">
              <SvgShop />
              <p>Sklep internetowy</p>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-6">
            <Link
              to="/brief-kompleksowa-kampania-reklamowa/"
              className="brief_link brief_link__branding"
              onMouseEnter={() => shake(true)}
              onMouseLeave={() => shake(false)}
            >
              <SvgBranding isShaking={isShaking} />
              <p>
                Kompleksowa <br /> kampania reklamowa
              </p>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-6">
            <Link
              to="/brief-branding/"
              className="brief_link brief_link__branding_4"
            >
              <SvgBrand />
              <p>Branding</p>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Brief;
